import {checkNullOrEmptyValue} from '../../../utility/validations';

export const alertModel = data => {
  const newData = data?.data;
  const alertData = data?.data?.data;
  const payload = {};

  payload.data = parseAlertsItems(alertData);
  payload.error = checkNullOrEmptyValue(newData?.error);
  payload.meta = checkNullOrEmptyValue(newData?.meta);
  payload.message = checkNullOrEmptyValue(newData?.message);
  payload.success = data.success;
  payload.status = data.status;

  return payload;
};

// alerts []
const parseAlertsItems = (resultsData = []) => {
  const payload = [];

  resultsData.forEach(item => {
    const resultItem = {};

    resultItem.id = checkNullOrEmptyValue(item?.id);
    resultItem.caseNumber = checkNullOrEmptyValue(item?.caseNumber);
    resultItem.descriptor = checkNullOrEmptyValue(item?.descriptor);
    resultItem.dataSource = checkNullOrEmptyValue(item?.dataSource);
    resultItem.transactionDate = checkNullOrEmptyValue(item?.transactionDate);
    resultItem.caseDate = checkNullOrEmptyValue(item?.caseDate);
    resultItem.amount = checkNullOrEmptyValue(item?.amount);
    resultItem.currency = checkNullOrEmptyValue(item?.currency);
    resultItem.processStatus = checkNullOrEmptyValue(item?.processStatus);
    resultItem.arn = checkNullOrEmptyValue(item?.arn);
    resultItem.binNumber = checkNullOrEmptyValue(item?.binNumber);
    resultItem.last4 = checkNullOrEmptyValue(item?.last4);
    resultItem.createdAt = checkNullOrEmptyValue(item?.createdAt);
    resultItem.errors = checkNullOrEmptyValue(item?.errors);
    resultItem.merchantName = checkNullOrEmptyValue(item?.client?.merchantName);

    payload.push(resultItem);
  });

  return payload;
};

export const alertCountModel = data => {
  const newData = data?.data;
  const alertData = data?.data?.data;
  const payload = {};

  payload.data = parseAlertsCountItems(alertData);
  payload.error = checkNullOrEmptyValue(newData?.error);
  payload.meta = checkNullOrEmptyValue(newData?.meta);
  payload.message = checkNullOrEmptyValue(newData?.message);
  payload.success = data.success;
  payload.status = data.status;

  return payload;
};

// count []
const parseAlertsCountItems = (resultsData = []) => {
  const payload = [];

  resultsData.forEach(item => {
    const resultItem = {};

    resultItem.processStatus = checkNullOrEmptyValue(item?.processStatus);
    resultItem.count = checkNullOrEmptyValue(item?.count);

    payload.push(resultItem);
  });

  return payload;
};
