import React, {useState, useEffect, useCallback} from 'react';
import {Select, Empty} from 'antd';
import {useFormik} from 'formik';
import {useDispatch} from 'react-redux';
import {useSearchParams} from 'react-router-dom';
import Card from '@mui/material/Card';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import debounce from 'lodash/debounce';
import Icon from '@mui/material/Icon';

import MDBox from '../../components/MDBox';
import MDButton from '../../components/MDButton';
import DashboardLayout from '../../layouts/DashboardLayout';
import DashboardNavbar from '../../components/DashboardNavbar';
import DataTable from '../../components/DataTable';
import LabelFilterView from '../../components/LabelFilterView';
import ClientCrmModal from '../../components/ClientCrmModal';

import Styles from './styles';
import Strings from '../../../constants/strings';
import Jsons from '../../../constants/jsons';
import {
  fetchClientCrmsApiCall,
  fetchClientsByNameApiCall,
  fetchCrmCredentialFieldsApiCall,
  updateClientCrmApiCall,
  createClientCrmApiCall,
} from '../../../services/api/apiCalls';
import {showToast} from '../../../redux/slices/commonSlice';
import {getQueryParams} from '../../../utility/helpers';
import {
  clientCrmValidation,
  crmDynamicFieldValidation,
} from '../../../utility/validations';

const ClientCrmPage = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const clientIdParam = searchParams.get('clientId');
  const clientNameParam = searchParams.get('clientName');

  const [storeClientCrms, setStoreClientCrms] = useState([]);
  const [storeClients, setStoreClients] = useState([]);
  const [crmFields, setCrmFields] = useState([]);
  const [paginationMeta, setPaginationMeta] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [crmInfoData, setCrmInfoData] = useState('');

  const [clientId, setClientId] = useState(clientIdParam);
  const [selectedClient, setSelectedClient] = useState(clientNameParam);
  const [serviceProvider, setServiceProvider] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isClientListLoading, setIsClientListLoading] = useState(false);
  const [isEditBtnLoading, setIsEditBtnLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isCrmUpdate, setIsCrmUpdate] = useState(false);

  useEffect(() => {
    getClientCrmsListData(currentPage);
    getClientsByNameListData(clientNameParam);
  }, []);

  const initialValues = crmFields.reduce((acc, field) => {
    if (isCrmUpdate) {
      if (crmInfoData?.credentials[field.name]) {
        acc[field.name] = crmInfoData?.credentials[field.name];
      }
      return acc;
    } else {
      acc[field.name] = '';
      return acc;
    }
  }, {});

  const formikCrm = useFormik({
    initialValues: {
      serviceProvider: crmInfoData.serviceProvider || null,
      email: crmInfoData.email || '',
      ...(!isCrmUpdate && {
        clientId: null,
      }),
    },
    enableReinitialize: true,
    validationSchema: clientCrmValidation(!isCrmUpdate),
    onSubmit: () => null,
  });

  const formikCrm1 = useFormik({
    initialValues,
    validationSchema: crmDynamicFieldValidation(crmFields),
    enableReinitialize: true,
    onSubmit: () => handleSubmitClicked(),
  });

  const debouncedFetchOptions = useCallback(
    debounce(searchValue => {
      getClientsByNameListData(searchValue);
    }, 500),
    [],
  );

  // api call code
  const getClientCrmsListData = async page => {
    setIsLoading(true);
    setCurrentPage(page);
    const params = {
      clientId,
      serviceProvider,
      page,
    };
    const queryString = getQueryParams(params);

    const responseData = await fetchClientCrmsApiCall(queryString);
    if (responseData?.success) {
      setStoreClientCrms(responseData?.data);
      setPaginationMeta(responseData?.meta);
    }

    setIsLoading(false);
  };

  const handleResetClicked = async page => {
    setClientId(null);
    setServiceProvider(null);
    setSelectedClient(null);

    setIsLoading(true);
    setCurrentPage(page);
    const params = {
      page,
    };
    const queryString = getQueryParams(params);

    const responseData = await fetchClientCrmsApiCall(queryString);
    if (responseData?.success) {
      setStoreClientCrms(responseData?.data);
      setPaginationMeta(responseData?.meta);
    }

    setIsLoading(false);
    getClientsByNameListData();
  };

  const getClientsByNameListData = async (name = '') => {
    setStoreClients([]);
    setIsClientListLoading(true);

    const responseData = await fetchClientsByNameApiCall(name);
    if (responseData?.success) {
      const data = responseData?.data.map(item => {
        return {
          value: item?.id,
          label: item?.merchantName,
        };
      });
      setStoreClients(data);
    }

    setIsClientListLoading(false);
  };

  const handleSubmitClicked = async () => {
    setIsEditBtnLoading(true);

    const bodyData = {
      email: formikCrm.values?.email,
      serviceProvider: formikCrm.values.serviceProvider,
      credentials: formikCrm1.values,
    };

    const responseData = isCrmUpdate
      ? await updateClientCrmApiCall(crmInfoData?.id, bodyData)
      : await createClientCrmApiCall({
          ...bodyData,
          clientId: formikCrm.values.clientId,
        });

    if (responseData?.success) {
      dispatch(showToast({msg: responseData?.message}));
      onModalCancel();
      getClientCrmsListData(isCrmUpdate ? currentPage : 1);
    }
    setIsEditBtnLoading(false);
  };

  const getCrmCredentialFields = async provider => {
    setIsLoading(true);

    const responseData = await fetchCrmCredentialFieldsApiCall(provider);
    if (responseData?.success) {
      const credentialFields = responseData?.data?.credentialFields;
      const data = credentialFields?.map(item => {
        return {
          name: item.slug,
          label: item.displayName,
          type: 'text',
          isEncrypted: item?.isEncrypted,
          validation: {
            required: true,
          },
        };
      });
      setCrmFields(data);
    }

    setIsLoading(false);
  };

  // logical code
  // const checkIsSearchDisabled = () => {
  //   if (clientId || serviceProvider) return false;
  //   else return true;
  // };

  const onAddClicked = () => {
    formikCrm.resetForm();
    formikCrm1.resetForm();
    setIsModalVisible(true);
    setCrmInfoData('');
    setIsCrmUpdate(false);
  };

  const onEditClicked = item => {
    formikCrm.resetForm();
    formikCrm1.resetForm();
    setIsModalVisible(true);
    setCrmInfoData(item);
    setIsCrmUpdate(true);

    getCrmCredentialFields(item?.serviceProvider);
  };

  const onModalCancel = () => {
    setIsModalVisible(false);
    formikCrm.resetForm();
    formikCrm1.resetForm();
    setCrmFields([]);
  };

  // render ui
  const renderHeader = () => {
    return (
      <MDBox>
        <MDButton onClick={onAddClicked}>
          <Icon sx={Styles.addIcon}>add</Icon>
          {Strings.addCrm}
        </MDButton>
      </MDBox>
    );
  };

  const renderFilterSection = () => {
    return (
      <Card sx={Styles.searchContainer}>
        {/* search view */}
        <MDBox sx={Styles.searchView}>
          <LabelFilterView label={Strings.clients}>
            <Select
              showSearch
              filterOption={false}
              value={selectedClient}
              options={storeClients}
              size={'large'}
              optionFilterProp="label"
              placeholder={Strings.selectClient}
              onChange={(value, data) => {
                setClientId(value);
                setSelectedClient(data?.label);
              }}
              onSearch={debouncedFetchOptions}
              notFoundContent={
                isClientListLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )
              }
              style={Styles.inputView}
            />
          </LabelFilterView>
          <LabelFilterView label={Strings.provider}>
            <Select
              showSearch
              value={serviceProvider}
              size={'large'}
              placeholder={Strings.selectStatus}
              optionFilterProp="label"
              onChange={value => setServiceProvider(value)}
              options={Jsons.serviceProviders}
              style={Styles.inputView}
            />
          </LabelFilterView>
        </MDBox>
        {/* search btn view */}
        <MDBox sx={Styles.searchBtnView}>
          <MDButton
            // disabled={checkIsSearchDisabled()}
            variant="outlined"
            sx={Styles.resetBtn}
            onClick={() => handleResetClicked(1)}>
            {Strings.reset}
          </MDButton>
          <MDButton onClick={() => getClientCrmsListData(1)}>
            {Strings.search}
          </MDButton>
        </MDBox>
      </Card>
    );
  };

  const renderTableSection = () => {
    return (
      <DataTable
        currentPage={currentPage}
        totalPage={paginationMeta?.count}
        paginationOnChange={page => getClientCrmsListData(page)}
        renderHeader={renderHeader()}
        table={{
          columns: [
            {Header: Strings.client, accessor: 'client'},
            {Header: Strings.provider, accessor: 'provider'},
            {Header: Strings.email, accessor: 'email'},
            {Header: Strings.actions, accessor: 'actions', width: '10%'},
          ],
          rows: storeClientCrms?.map(item => {
            return {
              client: item?.merchantName || '--',
              provider: item?.serviceProvider || '--',
              email: item?.email || '--',
              actions: (
                <MDBox>
                  <MDButton
                    variant="outlined"
                    color="dark"
                    onClick={() => onEditClicked(item)}
                    sx={Styles.editBtn}>
                    {Strings.edit}
                  </MDButton>
                </MDBox>
              ),
            };
          }),
        }}
      />
    );
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* search filter section */}
      {renderFilterSection()}
      {/* table section */}
      {renderTableSection()}
      {/* modal section */}
      <ClientCrmModal
        initialValues={initialValues}
        isCrmUpdate={isCrmUpdate}
        formik={formikCrm}
        formik1={formikCrm1}
        crmFields={crmFields}
        isModalVisible={isModalVisible}
        isBtnLoading={isEditBtnLoading}
        handleModalCancel={onModalCancel}
        onChangeServiceProvider={value => getCrmCredentialFields(value)}
      />
      <Backdrop
        sx={theme => ({color: '#fff', zIndex: theme.zIndex.drawer + 1})}
        open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </DashboardLayout>
  );
};

export default ClientCrmPage;
