import {createSlice} from '@reduxjs/toolkit';

const authSlice = createSlice({
  name: 'authSlice',
  initialState: {
    userSessionInfo: '',
  },
  reducers: {
    updateUserSessionInfo: (state, {payload}) => {
      state.userSessionInfo = payload;
    },
    resetAuthSlice: state => {
      state.userSessionInfo = '';
    },
  },
});

export default authSlice.reducer;

export const {updateUserSessionInfo, resetAuthSlice} = authSlice.actions;
