import axios from 'axios';

import api from './index';

// axios GET request service
export const getRequest = async url => {
  let response = null;
  await api
    .get(url)
    .then(res => {
      response = res;
    })
    .catch(error => {
      response = error;
    });
  return response;
};

// axios POST request service
export const postRequest = async (url, requestBody, extraHeaders = null) => {
  let response = null;
  await api
    .post(url, requestBody, extraHeaders || null)
    .then(res => {
      response = res;
    })
    .catch(error => {
      response = error;
    });
  return response;
};

// axios PUT request service
export const putRequest = async (url, requestBody) => {
  let response = null;
  await api
    .put(url, requestBody)
    .then(res => {
      response = res;
    })
    .catch(error => {
      response = error;
    });
  return response;
};

// axios PATCH request service
export const patchRequest = async (url, requestBody) => {
  let response = null;
  await api
    .patch(url, requestBody)
    .then(res => {
      response = res;
    })
    .catch(error => {
      response = error;
    });
  return response;
};

// axios DELETE request service
export const deleteRequest = async url => {
  let response = null;
  await api
    .delete(url)
    .then(res => {
      response = res;
    })
    .catch(error => {
      response = error;
    });
  return response;
};

export const customRequest = async config => {
  let response = null;
  await axios
    .request(config)
    .then(res => {
      response = res;
    })
    .catch(error => {
      response = error;
    });
  return response;
};
