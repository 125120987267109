import React, {useState, useEffect, useRef} from 'react';
import {Input, Modal} from 'antd';
import {useFormik} from 'formik';
import {useDispatch} from 'react-redux';
import * as Yup from 'yup';
import Card from '@mui/material/Card';
import Icon from '@mui/material/Icon';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import MDBox from '../../components/MDBox';
import MDButton from '../../components/MDButton';
import DashboardLayout from '../../layouts/DashboardLayout';
import DashboardNavbar from '../../components/DashboardNavbar';
import DataTable from '../../components/DataTable';
import LabelFilterView from '../../components/LabelFilterView';
import TextInput from '../../components/TextInput';
import MerchantModal from '../../components/MerchantModal';
import ClientCrmModal from '../../components/ClientCrmModal';

import Styles from './styles';
import Strings from '../../../constants/strings';
import Regex from '../../../constants/regex';
import RoutesName from '../../../services/routes/routesName';
import {
  fetchClientsApiCall,
  createClientApiCall,
  updateClientApiCall,
  createMerchantApiCall,
  fetchCrmCredentialFieldsApiCall,
  createClientCrmApiCall,
} from '../../../services/api/apiCalls';
import {showToast} from '../../../redux/slices/commonSlice';
import {
  merchantValidation,
  clientCrmValidation,
  crmDynamicFieldValidation,
} from '../../../utility/validations';

const ClientPage = () => {
  const dispatch = useDispatch();
  const inputRef = useRef(null);

  const [storeClients, setStoreClients] = useState([]);
  const [crmFields, setCrmFields] = useState([]);
  const [paginationMeta, setPaginationMeta] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [clientInfoData, setClientInfoData] = useState('');

  const [businessName, setBusinessName] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [isClientBtnLoading, setIsClientBtnLoading] = useState(false);
  const [isMerchantBtnLoading, setIsMerchantBtnLoading] = useState(false);
  const [isCrmBtnLoading, setIsCrmBtnLoading] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isMerchantVisible, setIsMerchantVisible] = useState(false);
  const [isCrmVisible, setIsCrmVisible] = useState(false);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isClientUpdate, setIsClientUpdate] = useState(false);

  const initialValues = crmFields.reduce((acc, field) => {
    acc[field.name] = '';
    return acc;
  }, {});

  useEffect(() => {
    getClientsListData(currentPage);
  }, []);

  const formik = useFormik({
    initialValues: {
      merchantName: '',
      email: '',
    },
    validationSchema: Yup.object().shape({
      merchantName: Yup.string().required(
        Strings.validationMsg.enterMerchantName,
      ),
      email: Yup.string()
        .required(Strings.validationMsg.enterValidEmail)
        .matches(Regex.emailValidation, Strings.validationMsg.enterValidEmail),
    }),
    onSubmit: values => {
      handleSubmitClicked(values);
    },
  });

  const formikMerchant = useFormik({
    initialValues: {
      midNum: '',
      dba: '',
      descriptor: '',
      bank: '',
      phoneNum: '',
      minTransactionAmount: '',
      maxTransactionAmount: '',
    },
    validationSchema: merchantValidation,
    onSubmit: values => handleMerchantSubmitClicked(values),
  });

  const formikCrm = useFormik({
    initialValues: {
      serviceProvider: null,
      email: '',
    },
    validationSchema: clientCrmValidation,
    onSubmit: () => null,
  });

  const formikCrm1 = useFormik({
    initialValues,
    validationSchema: crmDynamicFieldValidation(crmFields),
    enableReinitialize: true,
    onSubmit: values => handleCrmSubmitClicked(values),
  });

  // api call code
  const getClientsListData = async page => {
    setIsLoading(true);
    setCurrentPage(page);

    const responseData = await fetchClientsApiCall(page, businessName);
    if (responseData?.success) {
      setStoreClients(responseData?.data);
      setPaginationMeta(responseData?.meta);
    }

    setIsLoading(false);
  };

  const handleResetClicked = async page => {
    setIsLoading(true);
    setBusinessName('');
    setCurrentPage(page);

    const responseData = await fetchClientsApiCall(page);
    if (responseData?.success) {
      setStoreClients(responseData?.data);
      setPaginationMeta(responseData?.meta);
    }

    setIsLoading(false);
  };

  const handleSubmitClicked = async values => {
    setIsClientBtnLoading(true);

    const responseData = isClientUpdate
      ? await updateClientApiCall(clientInfoData?.id, values)
      : await createClientApiCall(values);

    if (responseData?.success) {
      dispatch(showToast({msg: responseData?.message}));
      getClientsListData(isClientUpdate ? currentPage : 1);
      onModalCancel();
    }

    setIsClientBtnLoading(false);
  };

  const handleMerchantSubmitClicked = async values => {
    setIsMerchantBtnLoading(true);

    const responseData = await createMerchantApiCall({
      ...values,
      clientId: clientInfoData?.id,
    });
    if (responseData?.success) {
      dispatch(showToast({msg: responseData?.message}));
      getClientsListData(currentPage);
      onMerchantModalCancel();
    }

    setIsMerchantBtnLoading(false);
  };

  const handleCrmSubmitClicked = async () => {
    setIsCrmBtnLoading(true);

    const bodyData = {
      clientId: clientInfoData?.id,
      email: formikCrm.values?.email,
      serviceProvider: formikCrm.values.serviceProvider,
      credentials: formikCrm1.values,
    };

    const responseData = await createClientCrmApiCall(bodyData);
    if (responseData?.success) {
      dispatch(showToast({msg: responseData?.message}));
      getClientsListData(currentPage);
      onCrmModalCancel();
    }

    setIsCrmBtnLoading(false);
  };

  const getCrmCredentialFields = async provider => {
    setIsLoading(true);

    const responseData = await fetchCrmCredentialFieldsApiCall(provider);
    if (responseData?.success) {
      const credentialFields = responseData?.data?.credentialFields;
      const data = credentialFields?.map(item => {
        return {
          name: item.slug,
          label: item.displayName,
          isEncrypted: item?.isEncrypted,
          type: 'text',
          validation: {
            required: true,
          },
        };
      });
      setCrmFields(data);
    }

    setIsLoading(false);
  };

  // logical code
  // const checkIsSearchDisabled = () => {
  //   if (businessName) return false;
  //   else return true;
  // };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      getClientsListData(1);
    }
  };

  const onAddClicked = () => {
    formik.resetForm();
    setIsModalVisible(true);
    setIsClientUpdate(false);
  };

  // handle action menu
  const onMenuClicked = (event, item) => {
    setIsMenuVisible(event.currentTarget);
    setClientInfoData(item);
  };

  const onMenuClose = () => {
    setIsMenuVisible(false);
  };

  // action menu item handlers
  const onEditClicked = item => {
    formik.resetForm();
    setIsModalVisible(true);
    setIsClientUpdate(true);

    formik.setFieldValue('merchantName', item?.merchantName);
    formik.setFieldValue('email', item?.email);
    onMenuClose();
  };

  const onAddMerchantClicked = () => {
    formikMerchant.resetForm();
    setIsMerchantVisible(true);
    onMenuClose();
  };

  const onAddCrmClicked = () => {
    formikCrm.resetForm();
    setIsCrmVisible(true);
    onMenuClose();
  };

  const onViewCrmClicked = (id, merchantName) => {
    window.open(
      `${RoutesName.clientCrm}?clientId=${id}&clientName=${merchantName}`,
    );
  };

  const onViewMerchantClicked = (id, merchantName) => {
    window.open(
      `${RoutesName.merchants}?clientId=${id}&clientName=${merchantName}`,
    );
  };

  // close modal views
  const onModalCancel = () => {
    setIsModalVisible(false);
    formik.resetForm();
  };

  const onMerchantModalCancel = () => {
    setIsMerchantVisible(false);
    formikMerchant.resetForm();
  };

  const onCrmModalCancel = () => {
    setIsCrmVisible(false);
    formikCrm.resetForm();
    formikCrm1.resetForm();
    setCrmFields([]);
  };

  // render ui
  const renderHeader = () => {
    return (
      <MDBox>
        <MDButton onClick={onAddClicked}>
          <Icon sx={Styles.addIcon}>add</Icon>
          {Strings.addClient}
        </MDButton>
      </MDBox>
    );
  };

  const renderFilterSection = () => {
    return (
      <Card sx={Styles.searchContainer}>
        {/* search view */}
        <MDBox sx={Styles.searchView}>
          <LabelFilterView label={Strings.businessName}>
            <Input
              ref={inputRef}
              value={businessName}
              placeholder={Strings.enterBusinessName}
              size={'large'}
              onChange={text => setBusinessName(text?.target?.value)}
              style={Styles.inputView}
              onKeyDown={handleKeyDown}
            />
          </LabelFilterView>
        </MDBox>
        {/* search btn view */}
        <MDBox sx={Styles.searchBtnView}>
          <MDButton
            // disabled={checkIsSearchDisabled()}
            variant="outlined"
            sx={Styles.resetBtn}
            onClick={() => handleResetClicked(1)}>
            {Strings.reset}
          </MDButton>
          <MDButton onClick={() => getClientsListData(1)}>
            {Strings.search}
          </MDButton>
        </MDBox>
      </Card>
    );
  };

  const renderTableSection = () => {
    return (
      <DataTable
        currentPage={currentPage}
        totalPage={paginationMeta?.count}
        paginationOnChange={page => getClientsListData(page)}
        renderHeader={renderHeader()}
        table={{
          columns: [
            {Header: Strings.businessName, accessor: 'businessName'},
            {Header: Strings.businessEmail, accessor: 'businessEmail'},
            {Header: Strings.crm, accessor: 'crmCount'},
            {Header: Strings.merchant, accessor: 'merchantCount'},
            {Header: Strings.actions, accessor: 'actions', width: '10%'},
          ],
          rows: storeClients?.map(item => {
            return {
              businessName: item?.merchantName || '--',
              businessEmail: item?.email || '--',
              crmCount: (
                <MDButton
                  variant="text"
                  color="info"
                  onClick={() =>
                    onViewCrmClicked(item?.id, item?.merchantName)
                  }>
                  {item?.availableClientCRM.length || '--'}
                </MDButton>
              ),
              merchantCount: (
                <MDButton
                  variant="text"
                  color="info"
                  onClick={() =>
                    onViewMerchantClicked(item?.id, item?.merchantName)
                  }>
                  {item?.merchants.length || '--'}
                </MDButton>
              ),
              actions: (
                <MDBox>
                  <MDButton
                    variant="text"
                    onClick={event => onMenuClicked(event, item)}>
                    <Icon>more_vert</Icon>
                  </MDButton>
                  <Menu
                    anchorEl={isMenuVisible}
                    open={Boolean(isMenuVisible)}
                    onClose={onMenuClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                    slotProps={{
                      paper: {
                        sx: Styles.menuBoxShadow,
                      },
                    }}>
                    <MenuItem
                      onClick={() => {
                        onEditClicked(clientInfoData);
                      }}>
                      {Strings.editClient}
                    </MenuItem>
                    <MenuItem onClick={onAddCrmClicked}>
                      {Strings.addCrm}
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        onViewCrmClicked(
                          clientInfoData?.id,
                          clientInfoData?.merchantName,
                        )
                      }>
                      {Strings.viewCRMs}
                    </MenuItem>
                    <MenuItem onClick={onAddMerchantClicked}>
                      {Strings.addMerchant}
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        onViewMerchantClicked(
                          clientInfoData?.id,
                          clientInfoData?.merchantName,
                        )
                      }>
                      {Strings.viewMerchants}
                    </MenuItem>
                  </Menu>
                </MDBox>
              ),
            };
          }),
        }}
      />
    );
  };

  const renderAddClientModal = () => {
    return (
      <Modal
        title={isClientUpdate ? Strings.editClient : Strings.addClient}
        open={isModalVisible}
        footer={null}
        onCancel={onModalCancel}
        width={'35%'}
        style={Styles.modalStyle}>
        <MDBox sx={Styles.modalBodyContainer}>
          <TextInput
            name={'merchantName'}
            label={Strings.businessName}
            placeholder={Strings.enterBusinessName}
            value={formik.values.merchantName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errors={formik.errors.merchantName}
            touched={formik.touched.merchantName}
            style={Styles.addInputView}
          />
          <TextInput
            name={'email'}
            label={Strings.email}
            placeholder={Strings.enterEmail}
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errors={formik.errors.email}
            touched={formik.touched.email}
            style={Styles.addInputView}
          />
          <MDButton
            loading={isClientBtnLoading}
            sx={Styles.submitBtn}
            onClick={formik.handleSubmit}>
            {isClientUpdate ? Strings.update : Strings.save}
          </MDButton>
        </MDBox>
      </Modal>
    );
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* search filter section */}
      {renderFilterSection()}
      {/* table section */}
      {renderTableSection()}
      {/* modal section */}
      {renderAddClientModal()}
      <MerchantModal
        formik={formikMerchant}
        isMerchantUpdate={false}
        isClientPage
        isModalVisible={isMerchantVisible}
        isBtnLoading={isMerchantBtnLoading}
        handleModalCancel={onMerchantModalCancel}
      />
      <ClientCrmModal
        formik={formikCrm}
        formik1={formikCrm1}
        crmFields={crmFields}
        isCrmUpdate={false}
        isClientPage
        isModalVisible={isCrmVisible}
        isBtnLoading={isCrmBtnLoading}
        handleModalCancel={onCrmModalCancel}
        onChangeServiceProvider={value => getCrmCredentialFields(value)}
      />
      <Backdrop
        sx={theme => ({color: '#fff', zIndex: theme.zIndex.drawer + 1})}
        open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </DashboardLayout>
  );
};

export default ClientPage;
