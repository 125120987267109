import React, {useState, useEffect} from 'react';
import {Modal, Select} from 'antd';
import {useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';

import TextInput from '../TextInput';
import MDBox from '../MDBox';
import MDButton from '../MDButton';
import FieldWrapperView from '../FieldWrapperView';
import ClientDropdown from '../ClientDropdown';

import Strings from '../../../constants/strings';
import Styles from './styles';
import Jsons from '../../../constants/jsons';
import {validateCrmCredentialApiCall} from '../../../services/api/apiCalls';
import {showToast} from '../../../redux/slices/commonSlice';

const ClientCrmModal = props => {
  const {
    formik,
    formik1,
    crmFields,
    isCrmUpdate,
    isModalVisible,
    isBtnLoading,
    isClientPage,
    handleModalCancel,
    onChangeServiceProvider,
    initialValues,
  } = props;

  const dispatch = useDispatch();

  const [storeClients, setStoreClients] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [isTestConDisabled, setIsTestConDisabled] = useState(false);

  useEffect(() => {
    if (isCrmUpdate) {
      setIsSaveDisabled(false);
      setIsTestConDisabled(true);
    } else {
      setIsSaveDisabled(true);
      setIsTestConDisabled(false);
    }
  }, [isCrmUpdate]);

  useEffect(() => {
    if (isCrmUpdate) {
      const hasChanged = Object.keys(initialValues).some(
        key => formik1.values[key] !== initialValues[key],
      );
      setIsTestConDisabled(!hasChanged);
      setIsSaveDisabled(hasChanged);
    }
  }, [formik1.values]);

  // api call code
  const handleTestConnection = async () => {
    const isFormikValid = Object.values(formik.values).includes('');
    const isFormi1Valid = Object.values(formik1.values).includes('');

    if (isFormikValid)
      formik.validateForm().then(() => {
        formik.setTouched(
          Object.keys(formik.initialValues).reduce((acc, field) => {
            acc[field] = true;
            return acc;
          }, {}),
        );
      });
    if (isFormi1Valid)
      formik1.validateForm().then(() => {
        formik1.setTouched(
          Object.keys(formik1.initialValues).reduce((acc, field) => {
            acc[field] = true;
            return acc;
          }, {}),
        );
      });

    if (!isFormikValid && !isFormi1Valid) {
      setIsLoading(true);
      const bodyData = {
        serviceProvider: formik.values.serviceProvider,
        credentials: formik1.values,
      };

      const responseData = await validateCrmCredentialApiCall(bodyData);
      if (responseData.success) {
        dispatch(showToast({msg: responseData?.message}));
        setIsSaveDisabled(false);
      }

      setIsLoading(false);
    }
  };

  return (
    <Modal
      title={isCrmUpdate ? Strings.editCrm : Strings.addCrm}
      open={isModalVisible}
      footer={null}
      onCancel={handleModalCancel}
      width={'35%'}
      style={Styles.modalStyle}>
      <MDBox sx={Styles.modalContainer}>
        {!isCrmUpdate && !isClientPage && (
          <FieldWrapperView
            label={Strings.client}
            errors={formik.errors.clientId}
            touched={formik.touched.clientId}>
            <ClientDropdown
              selectedValue={formik.values.clientId}
              storeClients={storeClients}
              setStoreClients={setStoreClients}
              onChange={value => formik.setFieldValue('clientId', value)}
              style={Styles.inputView}
            />
          </FieldWrapperView>
        )}
        <FieldWrapperView
          label={Strings.serviceProvider}
          errors={formik.errors.serviceProvider}
          touched={formik.touched.serviceProvider}>
          <Select
            name={'serviceProvider'}
            size={'large'}
            value={formik.values.serviceProvider}
            placeholder={Strings.SelectServiceProvider}
            optionFilterProp="label"
            options={Jsons.serviceProviders}
            onChange={value => {
              formik.setFieldValue('serviceProvider', value);
              onChangeServiceProvider(value);
            }}
            onBlur={formik.handleBlur}
            style={Styles.inputView}
          />
        </FieldWrapperView>
        <TextInput
          name={'email'}
          label={Strings.email}
          placeholder={Strings.enterEmail}
          value={formik.values.email}
          errors={formik.errors.email}
          touched={formik.touched.email}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          style={Styles.inputView}
        />
        {crmFields?.map((field, index) => (
          <TextInput
            isPassword={field?.isEncrypted}
            key={index}
            name={field.name}
            label={field.label}
            placeholder={`Enter ${field.name}`}
            value={formik1.values[field.name]}
            errors={formik1.errors[field.name]}
            touched={formik1.touched[field.name]}
            onBlur={formik1.handleBlur}
            onChange={formik1.handleChange}
            style={Styles.inputView}
          />
        ))}
        <MDButton
          disabled={isTestConDisabled}
          sx={Styles.testConnection}
          onClick={handleTestConnection}>
          {Strings.testConnection}
        </MDButton>
        <MDButton
          disabled={isSaveDisabled}
          loading={isBtnLoading}
          sx={Styles.submitBtn}
          onClick={() => {
            formik.handleSubmit();
            formik1.handleSubmit();
          }}>
          {isCrmUpdate ? Strings.update : Strings.save}
        </MDButton>
      </MDBox>
      <Backdrop
        sx={theme => ({color: '#fff', zIndex: theme.zIndex.drawer + 1})}
        open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Modal>
  );
};

// Setting default values for the props of MDInput
ClientCrmModal.defaultProps = {
  isCrmUpdate: true,
  isClientPage: false,
  crmFields: [],
};

// Typechecking props for the MDInput
ClientCrmModal.propTypes = {
  formik: PropTypes.any,
  formik1: PropTypes.any,
  crmFields: PropTypes.array,
  isCrmUpdate: PropTypes.bool,
  isModalVisible: PropTypes.bool,
  isBtnLoading: PropTypes.bool,
  isClientPage: PropTypes.bool,
  handleModalCancel: PropTypes.func,
  onChangeServiceProvider: PropTypes.func,
  initialValues: PropTypes.object,
};

export default ClientCrmModal;
