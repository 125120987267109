import React, {useEffect} from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import {useDispatch} from 'react-redux';

import RoutesName from './routesName';
import StorageKeys from '../storage/storageKeys';
import {getLocalStorageItem} from '../storage';
import {updateUserSessionInfo} from '../../redux/slices/authSlice';

const ProtectedRoute = () => {
  const dispatch = useDispatch();

  const isAuthenticated = getLocalStorageItem(StorageKeys.userSessionInfo);
  const userSessionInfo = getLocalStorageItem(StorageKeys.userSessionInfo);

  useEffect(() => {
    dispatch(updateUserSessionInfo(userSessionInfo));
  }, [userSessionInfo]);

  if (isAuthenticated) {
    return <Outlet />;
  }

  return <Navigate to={RoutesName.signIn} replace />;
};

export default ProtectedRoute;
