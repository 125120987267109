const Styles = {
  container: {
    width: '100%',
    paddingBottom: 20,
  },
  bodyContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerContainer: {
    display: 'flex',
    py: 2,
    px: 2,
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    ml: 15,
  },
  chartInfiBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    mb: 1,
  },
  colorBox: {
    width: 30,
    height: 20,
    borderRadius: 1,
    mr: 1.5,
  },
  dashView: {
    height: 1.5,
    width: '100%',
    backgroundColor: 'gray',
    mt: 1,
    mb: 1,
  },
};

export default Styles;
