import React, {useState, useEffect, useCallback} from 'react';
import {Select, Empty} from 'antd';
import CircularProgress from '@mui/material/CircularProgress';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';

import Strings from '../../../constants/strings';
import Styles from './styles';
import {fetchClientsByNameApiCall} from '../../../services/api/apiCalls';

const ClientDropdown = props => {
  const {
    selectedValue,
    storeClients,
    setStoreClients,
    onChange,
    showSearch,
    style,
    isNotSetVisible,
  } = props;

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getClientsByNameListData();
  }, []);

  const debouncedFetchOptions = useCallback(
    debounce(searchValue => {
      getClientsByNameListData(searchValue);
    }, 500),
    [],
  );

  // api call code
  const getClientsByNameListData = async (name = '') => {
    setStoreClients([]);
    setIsLoading(true);

    const responseData = await fetchClientsByNameApiCall(name);
    if (responseData?.success) {
      const data = responseData?.data.map(item => {
        return {
          value: item?.id,
          label: item?.merchantName,
        };
      });
      isNotSetVisible
        ? setStoreClients([{value: '0', label: Strings.notSet}, ...data])
        : setStoreClients(data);
    }

    setIsLoading(false);
  };

  return (
    <Select
      showSearch={showSearch}
      filterOption={false}
      value={selectedValue}
      options={storeClients}
      size={'large'}
      optionFilterProp="label"
      placeholder={Strings.selectClient}
      onChange={onChange}
      onSearch={debouncedFetchOptions}
      notFoundContent={
        isLoading ? (
          <CircularProgress color="inherit" size={20} />
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )
      }
      style={style || Styles.dropdownView}
    />
  );
};

// Setting default values for the props of MDInput
ClientDropdown.defaultProps = {
  showSearch: true,
  isNotSetVisible: false,
};

// Typechecking props for the MDInput
ClientDropdown.propTypes = {
  selectedValue: PropTypes.string,
  storeClients: PropTypes.array,
  setStoreClients: PropTypes.func,
  onChange: PropTypes.func,
  showSearch: PropTypes.bool,
  style: PropTypes.object,
  isNotSetVisible: PropTypes.bool,
};

export default ClientDropdown;
