import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate, Link} from 'react-router-dom';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import GoogleIcon from '@mui/icons-material/Google';

import MDBox from '../../components/MDBox';
import MDTypography from '../../components/MDTypography';
import TextInput from '../../components/TextInput';
import MDButton from '../../components/MDButton';
import PageLayout from '../../layouts/PageLayout';

import Strings from '../../../constants/strings';
import RoutesName from '../../../services/routes/routesName';
import Regex from '../../../constants/regex';
import StorageKeys from '../../../services/storage/storageKeys';
import Styles from './styles';
import {authBackgroundImg} from '../../../constants/images';
import {updateUserSessionInfo} from '../../../redux/slices/authSlice';
import {showToast} from '../../../redux/slices/commonSlice';
import {userLoginApiCall} from '../../../services/api/apiCalls';
import {
  setLocalStorageItem,
  getLocalStorageItem,
} from '../../../services/storage/index';

const SignInPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuthenticated = getLocalStorageItem(StorageKeys.userSessionInfo);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isAuthenticated) navigate(RoutesName.dashboard);
  }, [isAuthenticated]);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .required(Strings.validationMsg.enterValidEmail)
        .matches(Regex.emailValidation, Strings.validationMsg.enterValidEmail),
      password: Yup.string().required(Strings.validationMsg.enterValidPass),
    }),
    onSubmit: values => {
      handleSignInClicked(values);
    },
  });

  const handleSignInClicked = async values => {
    setIsLoading(true);
    const responseData = await userLoginApiCall(values);
    if (responseData?.success) {
      setLocalStorageItem(StorageKeys.userSessionInfo, responseData);
      dispatch(updateUserSessionInfo(responseData));
      dispatch(showToast({msg: responseData?.message}));
      navigate(RoutesName.dashboard);
    }
    setIsLoading(false);
  };

  const renderCardHeader = () => {
    return (
      <MDBox
        variant="gradient"
        bgColor="dark"
        borderRadius="lg"
        coloredShadow="dark"
        sx={Styles.cardHeaderContainer}>
        <MDTypography
          variant="h4"
          fontWeight="medium"
          color="white"
          sx={Styles.signinText}>
          {Strings.signIn}
        </MDTypography>
        <Grid container justifyContent="center" sx={Styles.logoContainer}>
          <GoogleIcon color="white" fontSize="large" />
        </Grid>
      </MDBox>
    );
  };

  const renderCardBody = () => {
    return (
      <MDBox sx={Styles.cardBodyContainer}>
        {/* email and password input */}
        <TextInput
          name={'email'}
          label={Strings.email}
          placeholder={Strings.enterEmail}
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          errors={formik.errors.email}
          touched={formik.touched.email}
          style={Styles.inputView}
        />
        <TextInput
          isPassword
          name={'password'}
          label={Strings.password}
          placeholder={Strings.enterPass}
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          errors={formik.errors.password}
          touched={formik.touched.password}
          style={Styles.inputView}
        />
        {/* forgot-password btn */}
        <MDTypography
          component={Link}
          to={RoutesName.forgotPassword}
          variant="body2"
          fontWeight="regular"
          color="dark">
          {Strings.forgotPassword}
        </MDTypography>
        {/* sign-in btn */}
        <MDBox sx={Styles.signinBtnView}>
          <MDButton loading={isLoading} fullWidth onClick={formik.handleSubmit}>
            {Strings.signIn}
          </MDButton>
        </MDBox>
      </MDBox>
    );
  };

  return (
    <PageLayout>
      <MDBox
        sx={{
          backgroundImage: () => `url(${authBackgroundImg})`,
          ...Styles.container,
        }}
      />
      <MDBox sx={Styles.cardContainer}>
        <Grid
          container
          spacing={1}
          justifyContent="center"
          alignItems="center"
          height="100%">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            <Card>
              {renderCardHeader()}
              {renderCardBody()}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </PageLayout>
  );
};

export default SignInPage;
