import React from 'react';
import {useNavigate} from 'react-router-dom';

import MDBox from '../../components/MDBox';
import MDButton from '../../components/MDButton';
import MDTypography from '../../components/MDTypography';
import PageLayout from '../../layouts/PageLayout';

import Strings from '../../../constants/strings';
import Styles from './styles';
import RoutesName from '../../../services/routes/routesName';

const NotFoundPage = () => {
  const navigate = useNavigate();

  return (
    <PageLayout>
      <MDBox sx={Styles.container}>
        <MDTypography
          variant="h4"
          fontWeight="medium"
          color="dark"
          sx={Styles.error404}>
          {Strings.erorr404}
        </MDTypography>
        <MDTypography
          variant="h4"
          fontWeight="medium"
          color="dark"
          sx={Styles.opsPageDoesntExist}>
          {Strings.opsPageDoesntExist}
        </MDTypography>
        <MDButton
          variant="outlined"
          color="dark"
          onClick={() => navigate(RoutesName.dashboard)}>
          {Strings.goBackToDashboard}
        </MDButton>
      </MDBox>
    </PageLayout>
  );
};

export default NotFoundPage;
