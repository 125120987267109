import React, {useEffect, useState} from 'react';
import Card from '@mui/material/Card';
import {DatePicker} from 'antd';
import CircularProgress from '@mui/material/CircularProgress';
import dayjs from 'dayjs';

import MDBox from '../../components/MDBox';
import MDButton from '../../components/MDButton';
import MDTypography from '../../components/MDTypography';
import DashboardLayout from '../../layouts/DashboardLayout';
import DashboardNavbar from '../../components/DashboardNavbar';
import DoughnutChart from '../../components/DoughnutChart';
import ClientDropdown from '../../components/ClientDropdown';
import LabelFilterView from '../../components/LabelFilterView';

import Styles from './styles';
import Strings from '../../../constants/strings';
import Jsons from '../../../constants/jsons';
import {fetchAlertsCountApiCall} from '../../../services/api/apiCalls';
import {getQueryParams} from '../../../utility/helpers';

const Dashboard = () => {
  const {RangePicker} = DatePicker;

  const [storeAlertsCount, setStoreAlertsCount] = useState([]);
  const [storeClients, setStoreClients] = useState([]);

  const [clientId, setClientId] = useState(null);
  const [caseDate, setCaseDate] = useState(null);
  const [caseDateString, setCaseDateString] = useState(null);

  const [isAlertsLoading, setIsAlertsLoading] = useState(false);

  useEffect(() => {
    getAlertsCountData();
  }, []);

  // api call code
  const getAlertsCountData = async () => {
    setIsAlertsLoading(true);

    const params = {
      caseStartDate: caseDate && caseDate[0],
      caseEndDate: caseDate && caseDate[1],
      clientId,
    };
    const queryString = getQueryParams(params);

    const responseData = await fetchAlertsCountApiCall(queryString);

    if (responseData?.success) {
      const mergedData = [];

      Jsons.alertStatus?.forEach(item => {
        const matchingItem = responseData?.data.find(
          mItem => mItem?.processStatus === item?.value,
        );
        if (matchingItem)
          mergedData.push({
            ...item,
            count: matchingItem ? matchingItem?.count : 0,
          });
      });

      setStoreAlertsCount(mergedData);
    }

    setIsAlertsLoading(false);
  };

  const handleResetClicked = async () => {
    setClientId(null);
    setCaseDate(null);
    setCaseDateString(null);
    setIsAlertsLoading(true);

    const params = {};
    const queryString = getQueryParams(params);

    const responseData = await fetchAlertsCountApiCall(queryString);

    if (responseData?.success) {
      const mergedData = [];

      Jsons.alertStatus?.forEach(item => {
        const matchingItem = responseData?.data.find(
          mItem => mItem?.processStatus === item?.value,
        );
        if (matchingItem)
          mergedData.push({
            ...item,
            count: matchingItem ? matchingItem?.count : 0,
          });
      });

      setStoreAlertsCount(mergedData);
    }

    setIsAlertsLoading(false);
  };

  const calculateTotalCount = () => {
    let count = 0;
    storeAlertsCount.map(item => (count += item?.count));

    return count;
  };

  const renderFilterSection = () => {
    return (
      <Card sx={Styles.searchContainer}>
        <MDTypography variant="h6" sx={{py: 2, px: 2}}>
          Alerts Filter
        </MDTypography>
        {/* search view */}
        <MDBox sx={Styles.searchView}>
          <LabelFilterView label={Strings.client}>
            <ClientDropdown
              isNotSetVisible
              selectedValue={clientId}
              storeClients={storeClients}
              setStoreClients={setStoreClients}
              onChange={value => setClientId(value)}
              style={Styles.inputView}
            />
          </LabelFilterView>
          <LabelFilterView label={Strings.caseDate}>
            <RangePicker
              maxDate={dayjs()}
              value={caseDateString}
              size={'large'}
              presets={Jsons.dateRangePresets}
              onChange={(dateStrings, date) => {
                setCaseDateString(dateStrings);
                setCaseDate(date);
              }}
              style={Styles.inputView}
            />
          </LabelFilterView>
        </MDBox>
        {/* search btn view */}
        <MDBox sx={Styles.searchBtnView}>
          <MDButton
            // disabled={checkIsSearchDisabled()}
            variant="outlined"
            sx={Styles.resetBtn}
            onClick={() => handleResetClicked()}>
            {Strings.reset}
          </MDButton>
          <MDButton onClick={() => getAlertsCountData()}>
            {Strings.search}
          </MDButton>
        </MDBox>
      </Card>
    );
  };

  // render ui
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* search filter section */}
      {renderFilterSection()}
      {isAlertsLoading ? (
        <Card sx={Styles.alertLoaderBox}>
          <CircularProgress color="inherit" />
        </Card>
      ) : (
        <MDBox sx={Styles.alertContainer}>
          <DoughnutChart
            title={`${Strings.allAlerts} (Total ${calculateTotalCount()})`}
            chart={{
              fulldata: storeAlertsCount,
              labels: storeAlertsCount?.map(item => item?.label),
              datasets: {
                backgroundColors: storeAlertsCount?.map(item => item?.color),
                data: storeAlertsCount?.map(item => item?.count),
              },
            }}
          />
        </MDBox>
      )}
    </DashboardLayout>
  );
};

export default Dashboard;
