const Styles = {
  container: {
    paddingBottom: 2,
    mr: 5,
  },
  bodyView: {
    mt: 1,
  },
};

export default Styles;
