const Styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    textAlign: 'center',
  },
  error404: {
    fontSize: '4rem',
    fontWeight: 'bold',
  },
  opsPageDoesntExist: {
    fontSize: '1.5rem',
    mt: 1,
    mb: 4,
  },
};

export default Styles;
