import React, {useState} from 'react';
import {Modal} from 'antd';
import PropTypes from 'prop-types';

import TextInput from '../TextInput';
import MDBox from '../MDBox';
import MDButton from '../MDButton';
import FieldWrapperView from '../FieldWrapperView';
import ClientDropdown from '../ClientDropdown';

import Strings from '../../../constants/strings';
import Styles from './styles';

const MerchantModal = props => {
  const {
    formik,
    isMerchantUpdate,
    isModalVisible,
    isBtnLoading,
    isClientPage,
    handleModalCancel,
  } = props;

  const [storeClients, setStoreClients] = useState([]);

  // api call code
  const restOfTheInputProps = (key, label) => {
    return {
      name: key,
      label,
      placeholder: `${Strings.enter} ${label.toLowerCase()}`,
      style: Styles.inputView,
      onBlur: formik.handleBlur(key),
      onChange: formik.handleChange(key),
    };
  };

  return (
    <Modal
      title={isMerchantUpdate ? Strings.editMerchant : Strings.addMerchant}
      open={isModalVisible}
      footer={null}
      onCancel={handleModalCancel}
      width={'35%'}
      style={Styles.modalStyle}>
      <MDBox sx={Styles.modalContainer}>
        {!isMerchantUpdate && !isClientPage && (
          <FieldWrapperView
            label={Strings.client}
            errors={formik.errors.clientId}
            touched={formik.touched.clientId}>
            <ClientDropdown
              selectedValue={formik.values.clientId}
              storeClients={storeClients}
              setStoreClients={setStoreClients}
              onChange={value => formik.setFieldValue('clientId', value)}
              style={Styles.dropdownView}
            />
          </FieldWrapperView>
        )}
        <TextInput
          value={formik.values.midNum}
          errors={formik.errors.midNum}
          touched={formik.touched.midNum}
          {...restOfTheInputProps('midNum', Strings.mid)}
        />
        <TextInput
          value={formik.values.dba}
          errors={formik.errors.dba}
          touched={formik.touched.dba}
          {...restOfTheInputProps('dba', Strings.dba)}
        />
        <TextInput
          value={formik.values.descriptor}
          errors={formik.errors.descriptor}
          touched={formik.touched.descriptor}
          {...restOfTheInputProps('descriptor', Strings.descriptor)}
        />
        <TextInput
          value={formik.values.bank}
          errors={formik.errors.bank}
          touched={formik.touched.bank}
          {...restOfTheInputProps('bank', Strings.bankName)}
        />
        <TextInput
          value={formik.values.phoneNum}
          errors={formik.errors.phoneNum}
          touched={formik.touched.phoneNum}
          {...restOfTheInputProps('phoneNum', Strings.phoneNumber)}
        />
        <TextInput
          value={formik.values.minTransactionAmount}
          errors={formik.errors.minTransactionAmount}
          touched={formik.touched.minTransactionAmount}
          {...restOfTheInputProps('minTransactionAmount', Strings.minAmount)}
        />
        <TextInput
          value={formik.values.maxTransactionAmount}
          errors={formik.errors.maxTransactionAmount}
          touched={formik.touched.maxTransactionAmount}
          {...restOfTheInputProps('maxTransactionAmount', Strings.maxAmount)}
        />
        <MDButton
          loading={isBtnLoading}
          sx={Styles.submitBtn}
          onClick={formik.handleSubmit}>
          {isMerchantUpdate ? Strings.update : Strings.save}
        </MDButton>
      </MDBox>
    </Modal>
  );
};

// Setting default values for the props of MDInput
MerchantModal.defaultProps = {
  isMerchantUpdate: true,
  isClientPage: false,
};

// Typechecking props for the MDInput
MerchantModal.propTypes = {
  formik: PropTypes.any,
  isMerchantUpdate: PropTypes.bool,
  isModalVisible: PropTypes.bool,
  isBtnLoading: PropTypes.bool,
  isClientPage: PropTypes.bool,
  handleModalCancel: PropTypes.func,
};

export default MerchantModal;
