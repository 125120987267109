/* eslint-disable no-undef */
import ProdConfig from './prodConfig';
import StagConfig from './stagConfig';

const env = process.env.REACT_APP_ENV;

const getConfigFile = () => {
  if (env === 'staging') return StagConfig;
  else return ProdConfig;
};

export default getConfigFile();
