import React from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

import MDBox from '../../components/MDBox';
import MDTypography from '../../components/MDTypography';
import TextInput from '../../components/TextInput';
import MDButton from '../../components/MDButton';
import PageLayout from '../../layouts/PageLayout';

import Strings from '../../../constants/strings';
import RoutesName from '../../../services/routes/routesName';
import Styles from './styles';
import {authBackgroundImg} from '../../../constants/images';
import {resetPasswordApiCall} from '../../../services/api/apiCalls';
import {showToast} from '../../../redux/slices/commonSlice';

const ResetPasswordPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const formik = useFormik({
    initialValues: {
      token,
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object().shape({
      newPassword: Yup.string()
        .required(Strings.validationMsg.enterValidPass)
        .min(8, Strings.validationMsg.youHaveEnterLeastDigit),
      confirmPassword: Yup.string()
        .oneOf(
          [Yup.ref('newPassword'), null],
          Strings.validationMsg.newPassConfirmPassNotMatch,
        )
        .required(Strings.validationMsg.enterValidPass),
    }),
    onSubmit: values => handleResetPassClicked(values),
  });

  const handleResetPassClicked = async values => {
    const responseData = await resetPasswordApiCall(values);

    if (responseData?.success) {
      dispatch(showToast({msg: responseData?.message}));
      navigate(RoutesName.signIn);
    }
  };

  const renderCardHeader = () => {
    return (
      <MDBox
        variant="gradient"
        bgColor="dark"
        borderRadius="lg"
        coloredShadow="dark"
        sx={Styles.cardHeaderContainer}>
        <MDTypography
          variant="h3"
          fontWeight="medium"
          color="white"
          sx={Styles.resetPasswordTxt}>
          {Strings.resetPassword}
        </MDTypography>
        <MDTypography
          display="block"
          variant="body2"
          color="white"
          sx={Styles.pleaseCreateNewPassTxt}>
          {Strings.pleaseCreateNewPass}
        </MDTypography>
      </MDBox>
    );
  };

  const renderCardBody = () => {
    return (
      <MDBox sx={Styles.cardBodyContainer}>
        {/* email input */}
        <TextInput
          isPassword
          name={'newPassword'}
          label={Strings.newPassword}
          placeholder={Strings.enterNewPass}
          value={formik.values.newPassword}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          errors={formik.errors.newPassword}
          touched={formik.touched.newPassword}
          style={Styles.inputView}
        />
        <TextInput
          isPassword
          name={'confirmPassword'}
          label={Strings.confirmPassword}
          placeholder={Strings.enterConfirmPass}
          value={formik.values.confirmPassword}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          errors={formik.errors.confirmPassword}
          touched={formik.touched.confirmPassword}
          style={Styles.inputView}
        />
        {/* reset btn */}
        <MDBox sx={Styles.restPassBtnView}>
          <MDButton fullWidth onClick={formik.handleSubmit}>
            {Strings.resetPassword}
          </MDButton>
        </MDBox>
      </MDBox>
    );
  };

  return (
    <PageLayout>
      <MDBox
        sx={{
          backgroundImage: () => `url(${authBackgroundImg})`,
          ...Styles.container,
        }}
      />
      <MDBox sx={Styles.cardContainer}>
        <Grid
          container
          spacing={1}
          justifyContent="center"
          alignItems="center"
          height="100%">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            <Card>
              {renderCardHeader()}
              {renderCardBody()}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </PageLayout>
  );
};

export default ResetPasswordPage;
