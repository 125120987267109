export const setLocalStorageItem = (key, data) => {
  try {
    const jsonData = JSON.stringify(data);
    localStorage.setItem(key, jsonData);
  } catch (error) {
    console.log(error);
  }
};

export const getLocalStorageItem = key => {
  try {
    const jsonData = localStorage.getItem(key);
    return jsonData != null ? JSON.parse(jsonData) : null;
  } catch (error) {
    console.log(error);
  }
};

export const clearLocalStorage = () => {
  try {
    localStorage.clear();
  } catch (error) {
    console.log(error);
  }
};

export const removeLocalStorage = key => {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    console.log(error);
  }
};
