const Styles = {
  container: {
    width: '100%',
    minHeight: '100vh',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    position: 'absolute',
  },
  cardContainer: {
    px: 1,
    width: '100%',
    height: '100vh',
    mx: 'auto',
  },
  cardHeaderContainer: {
    mx: 2,
    mt: -3,
    p: 2,
    mb: 1,
    textAlign: 'center',
  },
  cardBodyContainer: {
    pt: 4,
    pb: 3,
    px: 3,
  },
  logoContainer: {
    mt: 1,
    mb: 2,
  },
  signinText: {
    mt: 1,
    mb: 2,
  },
  signinBtnView: {
    mt: 3,
    mb: 3,
  },
  inputView: {
    mb: 3,
  },
};

export default Styles;
