import React from 'react';
import Icon from '@mui/material/Icon';

import Dashboard from '../../views/pages/Dashboard';
import MerchantPage from '../../views/pages/MerchantPage';
import ClientPage from '../../views/pages/ClientPage';
import AlertPage from '../../views/pages/AlertPage';
import AccountPage from '../../views/pages/AccountPage';
import ClientCrmPage from '../../views/pages/ClientCrmPage';

import RoutesName from './routesName';
import Strings from '../../constants/strings';
import Enums from '../../constants/enums';

const commonRoles = () => [Enums.USER_ROLE.ADMIN, Enums.USER_ROLE.SUPER_ADMIN];
const superAdminRoles = () => [Enums.USER_ROLE.SUPER_ADMIN];

const routes = [
  {
    type: Enums.ROUTES_TYPES.COLLAPSE,
    name: Strings.dashboard,
    key: 'dashboard',
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: RoutesName.dashboard,
    component: <Dashboard />,
    roles: commonRoles(),
  },
  {
    type: Enums.ROUTES_TYPES.COLLAPSE,
    name: Strings.clients,
    key: 'clients',
    icon: <Icon fontSize="small">list</Icon>,
    route: RoutesName.clients,
    component: <ClientPage />,
    roles: commonRoles(),
  },
  {
    type: Enums.ROUTES_TYPES.COLLAPSE,
    name: Strings.crms,
    key: 'crms',
    icon: <Icon fontSize="small">list</Icon>,
    route: RoutesName.clientCrm,
    component: <ClientCrmPage />,
    roles: commonRoles(),
  },
  {
    type: Enums.ROUTES_TYPES.COLLAPSE,
    name: Strings.merchants,
    key: 'merchants',
    icon: <Icon fontSize="small">list</Icon>,
    route: RoutesName.merchants,
    component: <MerchantPage />,
    roles: commonRoles(),
  },
  {
    type: Enums.ROUTES_TYPES.COLLAPSE,
    name: Strings.alerts,
    key: 'alerts',
    icon: <Icon fontSize="small">list</Icon>,
    route: RoutesName.alerts,
    component: <AlertPage />,
    roles: commonRoles(),
  },
  {
    type: Enums.ROUTES_TYPES.COLLAPSE,
    name: Strings.accounts,
    key: 'accounts',
    icon: <Icon fontSize="small">person</Icon>,
    route: RoutesName.accounts,
    component: <AccountPage />,
    roles: superAdminRoles(),
  },
];

export default routes;
