const Enums = {
  ROUTES_TYPES: {
    COLLAPSE: 'collapse',
  },
  CURRENCY: {
    USD: 'USD',
  },
  DATE_FORMAT: {
    MM_DD_YYYY: 'MM-DD-YYYY',
  },
  USER_ROLE: {
    ADMIN: 'admin',
    SUPER_ADMIN: 'super_admin',
  },
};

export default Enums;
