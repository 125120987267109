import React from 'react';
import PropTypes from 'prop-types';
import {Input} from 'antd';

import MDBox from '../MDBox';
import MDTypography from '../MDTypography';

import Styles from './styles';

const TextInput = props => {
  const {
    isPassword,
    name,
    label,
    placeholder,
    value,
    onChange,
    onBlur,
    errors,
    touched,
    style,
    inputStyle,
  } = props;

  const InputView = isPassword ? Input.Password : Input;

  return (
    <MDBox sx={style}>
      {label && (
        <MDTypography
          variant="body2"
          color="text"
          fontWeight="medium"
          sx={Styles.labelTxt}>
          {label}
        </MDTypography>
      )}
      <InputView
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        size="large"
        style={{...Styles.inputView, ...inputStyle}}
      />
      {errors && touched && (
        <MDTypography
          variant="body2"
          color="error"
          fontWeight="regular"
          sx={Styles.errorTxt}>
          {errors}
        </MDTypography>
      )}
    </MDBox>
  );
};

// Setting default values for the props of MDInput
TextInput.defaultProps = {
  isPassword: false,
};

// Typechecking props for the MDInput
TextInput.propTypes = {
  isPassword: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.any,
  onBlur: PropTypes.bool,
  errors: PropTypes.string,
  touched: PropTypes.bool,
  style: PropTypes.object,
  inputStyle: PropTypes.object,
};

export default TextInput;
