const RoutesName = {
  root: '/',
  dashboard: '/dashboard',
  merchants: '/merchants',
  mids: '/mids',
  clients: '/clients',
  profile: '/profile',
  accounts: '/accounts',
  signIn: '/sign-in',
  forgotPassword: '/forgot-password',
  resetPassword: 'reset-password',
  alerts: '/alerts',
  clientCrm: '/crms',
};

export default RoutesName;
