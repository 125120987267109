import React, {useState, useEffect, useRef} from 'react';
import {Input, Modal} from 'antd';
import {useFormik} from 'formik';
import {useDispatch} from 'react-redux';
import * as Yup from 'yup';
import Card from '@mui/material/Card';
import Icon from '@mui/material/Icon';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import MDBox from '../../components/MDBox';
import MDButton from '../../components/MDButton';
import DashboardLayout from '../../layouts/DashboardLayout';
import DashboardNavbar from '../../components/DashboardNavbar';
import LabelFilterView from '../../components/LabelFilterView';
import DataTable from '../../components/DataTable';
import TextInput from '../../components/TextInput';

import Styles from './styles';
import Strings from '../../../constants/strings';
import Enums from '../../../constants/enums';
import Regex from '../../../constants/regex';
import {showToast} from '../../../redux/slices/commonSlice';
import {
  fetchUsersApiCall,
  createUserApiCall,
  updateUserApiCall,
} from '../../../services/api/apiCalls';
import {getQueryParams} from '../../../utility/helpers';

const AccountPage = () => {
  const inputRef = useRef(null);
  const dispatch = useDispatch();

  const [storeUsers, setStoreUsers] = useState([]);
  const [paginationMeta, setPaginationMeta] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [userInfoData, setUserInfoData] = useState('');

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [isUserBtnLoading, setIsUserBtnLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isUserUpdate, setIsUserUpdate] = useState(false);

  useEffect(() => {
    getUsersListData(currentPage);
  }, []);

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string()
        .required(Strings.validationMsg.enterFirstName)
        .matches(
          Regex.nameValidation,
          Strings.validationMsg.enterValidFirstName,
        ),
      lastName: Yup.string()
        .required(Strings.validationMsg.enterLastName)
        .matches(
          Regex.nameValidation,
          Strings.validationMsg.enterValidLastName,
        ),
      email: Yup.string()
        .required(Strings.validationMsg.enterEmail)
        .matches(Regex.emailValidation, Strings.validationMsg.enterValidEmail),
    }),
    onSubmit: values => {
      handleSubmitClicked(values);
    },
  });

  // api call code
  const getUsersListData = async page => {
    setIsLoading(true);
    setCurrentPage(page);

    const params = {
      firstName,
      lastName,
      email,
      page,
    };
    const queryString = getQueryParams(params);

    const responseData = await fetchUsersApiCall(queryString);
    if (responseData?.success) {
      setStoreUsers(responseData?.data);
      setPaginationMeta(responseData?.meta);
    }

    setIsLoading(false);
  };

  const handleResetClicked = async page => {
    setFirstName('');
    setLastName('');
    setEmail('');

    setIsLoading(true);
    setCurrentPage(page);

    const params = {
      page,
    };
    const queryString = getQueryParams(params);

    const responseData = await fetchUsersApiCall(queryString);
    if (responseData?.success) {
      setStoreUsers(responseData?.data);
      setPaginationMeta(responseData?.meta);
    }

    setIsLoading(false);
  };

  const handleSubmitClicked = async values => {
    setIsUserBtnLoading(true);

    const responseData = isUserUpdate
      ? await updateUserApiCall(userInfoData?.id, values)
      : await createUserApiCall(values);

    if (responseData?.success) {
      dispatch(showToast({msg: responseData?.message}));
      getUsersListData(isUserUpdate ? currentPage : 1);
      onModalCancel();
    }

    setIsUserBtnLoading(false);
  };

  // logical code
  const onAddClicked = () => {
    formik.resetForm();
    setIsModalVisible(true);
    setIsUserUpdate(false);
  };

  const onEditClicked = item => {
    formik.resetForm();
    setIsModalVisible(true);
    setUserInfoData(item);
    setIsUserUpdate(true);

    formik.setFieldValue('firstName', item?.firstName);
    formik.setFieldValue('lastName', item?.lastName);
    formik.setFieldValue('email', item?.email);
  };

  const onModalCancel = () => {
    setIsModalVisible(false);
    formik.resetForm();
  };

  const mapUserRole = role => {
    if (!role) return '--';

    switch (role) {
      case Enums.USER_ROLE.ADMIN:
        return Strings.admin;
      case Enums.USER_ROLE.SUPER_ADMIN:
        return Strings.superAdmin;
    }
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      getUsersListData(1);
    }
  };

  // render ui
  const renderHeader = () => {
    return (
      <MDBox>
        <MDButton onClick={onAddClicked}>
          <Icon sx={Styles.addIcon}>add</Icon>
          {Strings.addAccount}
        </MDButton>
      </MDBox>
    );
  };

  const renderFilterSection = () => {
    return (
      <Card sx={Styles.searchContainer}>
        {/* search view */}
        <MDBox sx={Styles.searchView}>
          {/* first name */}
          <LabelFilterView label={Strings.firstName}>
            <Input
              ref={inputRef}
              value={firstName}
              placeholder={Strings.enterFirstName}
              size={'large'}
              onChange={text => setFirstName(text?.target?.value)}
              style={Styles.inputView}
              onKeyDown={handleKeyDown}
            />
          </LabelFilterView>
          {/* last name */}
          <LabelFilterView label={Strings.lastName}>
            <Input
              ref={inputRef}
              value={lastName}
              placeholder={Strings.enterLastName}
              size={'large'}
              onChange={text => setLastName(text?.target?.value)}
              style={Styles.inputView}
              onKeyDown={handleKeyDown}
            />
          </LabelFilterView>
          {/* email */}
          <LabelFilterView label={Strings.email}>
            <Input
              ref={inputRef}
              value={email}
              placeholder={Strings.enterEmail}
              size={'large'}
              onChange={text => setEmail(text?.target?.value)}
              style={Styles.inputView}
              onKeyDown={handleKeyDown}
            />
          </LabelFilterView>
        </MDBox>
        {/* search btn view */}
        <MDBox sx={Styles.searchBtnView}>
          <MDButton
            variant="outlined"
            sx={Styles.resetBtn}
            onClick={() => handleResetClicked(1)}>
            {Strings.reset}
          </MDButton>
          <MDButton onClick={() => getUsersListData(1)}>
            {Strings.search}
          </MDButton>
        </MDBox>
      </Card>
    );
  };

  const renderTableSection = () => {
    return (
      <DataTable
        currentPage={currentPage}
        totalPage={paginationMeta?.count}
        paginationOnChange={page => getUsersListData(page)}
        renderHeader={renderHeader()}
        table={{
          columns: [
            {Header: Strings.firstName, accessor: 'firstName'},
            {Header: Strings.lastName, accessor: 'lastName'},
            {Header: Strings.role, accessor: 'role'},
            {Header: Strings.email, accessor: 'email'},
            {Header: Strings.actions, accessor: 'actions', width: '10%'},
          ],
          rows: storeUsers?.map(item => {
            return {
              firstName: item?.firstName || '--',
              lastName: item?.lastName || '--',
              role: mapUserRole(item?.userRole),
              email: item?.email || '--',
              actions: (
                <MDBox>
                  <MDButton
                    variant="outlined"
                    color="dark"
                    onClick={() => onEditClicked(item)}
                    sx={Styles.editBtn}>
                    {Strings.edit}
                  </MDButton>
                </MDBox>
              ),
            };
          }),
        }}
      />
    );
  };

  const renderAddAccountModal = () => {
    return (
      <Modal
        title={isUserUpdate ? Strings.editAccount : Strings.addAccount}
        open={isModalVisible}
        footer={null}
        onCancel={onModalCancel}
        width={'35%'}
        style={Styles.modalStyle}>
        <MDBox sx={Styles.modalBodyContainer}>
          <TextInput
            name={'firstName'}
            label={Strings.firstName}
            placeholder={Strings.enterFirstName}
            value={formik.values.firstName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errors={formik.errors.firstName}
            touched={formik.touched.firstName}
            style={Styles.addInputView}
          />
          <TextInput
            name={'lastName'}
            label={Strings.lastName}
            placeholder={Strings.enterLastName}
            value={formik.values.lastName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errors={formik.errors.lastName}
            touched={formik.touched.lastName}
            style={Styles.addInputView}
          />
          <TextInput
            name={'email'}
            label={Strings.email}
            placeholder={Strings.enterEmail}
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errors={formik.errors.email}
            touched={formik.touched.email}
            style={Styles.addInputView}
          />
          <MDButton
            loading={isUserBtnLoading}
            sx={Styles.submitBtn}
            onClick={formik.handleSubmit}>
            {isUserUpdate ? Strings.update : Strings.save}
          </MDButton>
        </MDBox>
      </Modal>
    );
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* search filter section */}
      {renderFilterSection()}
      {/* table section */}
      {renderTableSection()}
      {/* modal section */}
      {renderAddAccountModal()}
      <Backdrop
        sx={theme => ({color: '#fff', zIndex: theme.zIndex.drawer + 1})}
        open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </DashboardLayout>
  );
};

export default AccountPage;
