import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

import MDBox from '../MDBox';
import MDTypography from '../MDTypography';

import Styles from './styles';

const FieldWrapperView = props => {
  const {label, errors, touched, style, children} = props;

  return (
    <Box sx={{...Styles.container, ...style}}>
      {label && (
        <MDTypography
          variant="body2"
          color="text"
          fontWeight="medium"
          sx={Styles.labelTxt}>
          {label}
        </MDTypography>
      )}
      <MDBox>{children}</MDBox>
      {errors && touched && (
        <MDTypography
          variant="body2"
          color="error"
          fontWeight="regular"
          sx={Styles.errorTxt}>
          {errors}
        </MDTypography>
      )}
    </Box>
  );
};

// Setting default values for the props of MDBox
FieldWrapperView.defaultProps = {
  label: '',
};

// Typechecking props for the MDBox
FieldWrapperView.propTypes = {
  label: PropTypes.string,
  errors: PropTypes.string,
  touched: PropTypes.bool,
  style: PropTypes.object,
  children: PropTypes.node,
};

export default FieldWrapperView;
