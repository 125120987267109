import {checkNullOrEmptyValue} from '../../../utility/validations';

export const commonModel = data => {
  const newData = data?.data;
  const payload = {};

  payload.data = newData?.data;
  payload.error = checkNullOrEmptyValue(newData?.error);
  payload.meta = checkNullOrEmptyValue(newData?.meta);
  payload.message = checkNullOrEmptyValue(newData?.message);
  payload.success = data.success;
  payload.status = data.status;

  return payload;
};
