const Styles = {
  alertContainer: {
    marginTop: 3,
    display: 'flex',
    flexDirection: 'row',
    // flexWrap: 'wrap',
  },
  alertLoaderBox: {
    height: 450,
    justifyContent: 'center',
    alignItems: 'center',
  },
  searchContainer: {
    mt: 3,
    mb: 3,
  },
  searchView: {
    display: 'flex',
    flexWrap: 'wrap',
    mt: 5,
    px: 6,
  },
  inputView: {
    width: 300,
  },
  searchBtnView: {
    display: 'flex',
    flexDirection: 'row',
    px: 5,
    mb: 3,
    mt: 0,
    justifyContent: 'right',
  },
  resetBtn: {
    mr: 2,
  },
};

export default Styles;
