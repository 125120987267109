import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Breadcrumbs as MuiBreadcrumbs} from '@mui/material';
import Icon from '@mui/material/Icon';

import MDBox from '../MDBox';
import MDTypography from '../MDTypography';

import RoutesName from '../../../services/routes/routesName';

const Breadcrumbs = props => {
  const {icon, title, light} = props;

  return (
    <MDBox mr={{xs: 0, xl: 8}}>
      <MuiBreadcrumbs
        sx={{
          '& .MuiBreadcrumbs-separator': {
            color: ({palette: {white, grey}}) =>
              light ? white.main : grey[600],
          },
        }}>
        <Link to={RoutesName.dashboard}>
          <MDTypography
            component="span"
            variant="body2"
            color={light ? 'white' : 'dark'}
            opacity={light ? 0.8 : 0.5}
            sx={{lineHeight: 0}}>
            <Icon>{icon}</Icon>
          </MDTypography>
        </Link>
        <MDTypography
          fontWeight="bold"
          textTransform="capitalize"
          variant="h6"
          color={light ? 'white' : 'dark'}
          noWrap>
          {title.replace('-', ' ')}
        </MDTypography>
      </MuiBreadcrumbs>
    </MDBox>
  );
};

// Setting default values for the props of Breadcrumbs
Breadcrumbs.defaultProps = {
  light: false,
};

// Typechecking props for the Breadcrumbs
Breadcrumbs.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  route: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  light: PropTypes.bool,
};

export default Breadcrumbs;
