const EndUrls = {
  // auth
  userLoginURL: '/auth/login',
  forgotPassword: '/auth/forgot-password',
  resetPassword: '/auth/reset-password',
  // client
  createClientURL: '/client',
  getClientsURL: (page, merchantName) =>
    `/client?page=${page}&merchantName=${merchantName}`,
  updateClientURL: id => `/client/${id}`,
  getClientsByNameURL: clientName =>
    clientName ? `/client/name/${clientName}` : `/client/name/`,
  // alert
  getAlertsURL: queryString => `/alert?${queryString}`,
  getAlertsCountURL: queryString => `/alert/status-counts?${queryString}`,
  // merchant
  createMerchant: '/merchant',
  getMerchant: queryString => `/merchant?${queryString}`,
  updateMerchant: id => `/merchant/${id}`,
  // client crm
  createClientCrmURL: '/client-crm',
  getCrmCredentialFieldsURL: provider =>
    `/client-crm/credential-fields/${provider}`,
  getClientCrmsURL: queryString => `/client-crm?${queryString}`,
  updateClientCrmURL: id => `/client-crm/${id}`,
  getMerchantCrmURL: merchantId => `/merchant-crm?merchantId=${merchantId}`,
  updateMerchantCrmURL: merchantId => `/merchant-crm/${merchantId}`,
  validateCrmCredentialURL: 'client-crm/validate-crm-credential',
  // user
  createUserURL: '/user/register',
  getUsersURL: queryString => `/user?${queryString}`,
  updateUserURL: id => `/user/${id}`,
};

export default EndUrls;
