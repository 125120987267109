import {checkNullOrEmptyValue} from '../../../utility/validations';

export const clientModel = data => {
  const newData = data?.data;
  const clientData = data?.data?.data;
  const payload = {};

  payload.data = parseClientsItems(clientData);
  payload.error = checkNullOrEmptyValue(newData?.error);
  payload.meta = checkNullOrEmptyValue(newData?.meta);
  payload.message = checkNullOrEmptyValue(newData?.message);
  payload.success = data.success;
  payload.status = data.status;

  return payload;
};

// clients []
const parseClientsItems = (resultsData = []) => {
  const payload = [];

  resultsData.forEach(item => {
    const resultItem = {};

    resultItem.id = checkNullOrEmptyValue(item?.id);
    resultItem.merchantName = checkNullOrEmptyValue(item?.merchantName);
    resultItem.email = checkNullOrEmptyValue(item?.email);
    resultItem.merchants = checkNullOrEmptyValue(item?.merchants);
    resultItem.availableClientCRM = checkNullOrEmptyValue(
      item?.availableClientCRM,
    );

    payload.push(resultItem);
  });

  return payload;
};
