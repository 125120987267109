import {configureStore} from '@reduxjs/toolkit';

import authSlice from './slices/authSlice';
import commonSlice from './slices/commonSlice';

const store = configureStore({
  reducer: {authSlice, commonSlice},
});

export default store;
