import {checkNullOrEmptyValue} from '../../../utility/validations';

export const crmModel = data => {
  const newData = data?.data;
  const crmData = data?.data?.data;
  const payload = {};

  payload.data = parseCrmItems(crmData);
  payload.error = checkNullOrEmptyValue(newData?.error);
  payload.meta = checkNullOrEmptyValue(newData?.meta);
  payload.message = checkNullOrEmptyValue(newData?.message);
  payload.success = data.success;
  payload.status = data.status;

  return payload;
};

// crms []
const parseCrmItems = (resultsData = []) => {
  const payload = [];

  resultsData.forEach(item => {
    const resultItem = {};

    resultItem.id = checkNullOrEmptyValue(item?.id);
    resultItem.merchantName = checkNullOrEmptyValue(item?.client?.merchantName);
    resultItem.serviceProvider = checkNullOrEmptyValue(item?.serviceProvider);
    resultItem.email = checkNullOrEmptyValue(item?.email);
    resultItem.credentials = checkNullOrEmptyValue(item?.credentials);

    payload.push(resultItem);
  });

  return payload;
};

export const merchantCrmModel = data => {
  const newData = data?.data;
  const crmData = data?.data?.data;
  const payload = {};

  payload.data = parseMerchantCrmItems(crmData);
  payload.error = checkNullOrEmptyValue(newData?.error);
  payload.meta = checkNullOrEmptyValue(newData?.meta);
  payload.message = checkNullOrEmptyValue(newData?.message);
  payload.success = data.success;
  payload.status = data.status;

  return payload;
};

// crms []
const parseMerchantCrmItems = (resultsData = []) => {
  const payload = [];

  resultsData.forEach(item => {
    const resultItem = {};

    resultItem.id = checkNullOrEmptyValue(item?.id);
    resultItem.clientId = checkNullOrEmptyValue(item?.clientId);
    resultItem.crmMerchantId = checkNullOrEmptyValue(item?.crmMerchantId);
    resultItem.discriminatorLabel = checkNullOrEmptyValue(
      item?.discriminatorLabel,
    );

    payload.push(resultItem);
  });

  return payload;
};
