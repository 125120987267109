import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import MDSnackbar from '../MDSnackbar';
import {showToast} from '../../../redux/slices/commonSlice';
import Strings from '../../../constants/strings';

const ToastBox = () => {
  const commonSlice = useSelector(state => state?.commonSlice);
  const {isErrorToast, isToastVisible, toastMessage} = commonSlice;

  const dispatch = useDispatch();

  return (
    <MDSnackbar
      color={isErrorToast ? 'error' : 'success'}
      icon={isErrorToast ? 'error' : 'check'}
      title={isErrorToast ? Strings.error : Strings.success}
      content={toastMessage}
      open={isToastVisible}
      onClose={() => dispatch(showToast({msg: '', isError: isErrorToast}))}
      close={() => dispatch(showToast({msg: '', isError: isErrorToast}))}
      bgWhite
    />
  );
};

export default ToastBox;
