import axios from 'axios';

import configuration from '../../configs';
import Strings from '../../constants/strings';
import store from '../../redux/store';
import StorageKeys from '../storage/storageKeys';
import RoutesName from '../routes/routesName';
import {getLocalStorageItem, clearLocalStorage} from '../storage/index';
import {showToast} from '../../redux/slices/commonSlice';
import {resetAuthSlice} from '../../redux/slices/authSlice';

// Axios instance
const api = axios.create({
  baseURL: configuration.apiBaseUrl,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
  },
});

// formating error msg
const showErrorMsg = data => {
  const errorMsg = data?.data?.error[0];
  const globalMsg = data?.data?.message;
  if (errorMsg) return errorMsg;
  else if (globalMsg) return globalMsg;
  else return Strings.validationMsg.somethingWentWrong;
};

// formating response structure
const handleApiResponse = data => {
  const statusCode = data?.status;

  if (statusCode === 200 || statusCode === 201) {
    return {
      ...data,
      success: true,
    };
  } else if (
    statusCode === 400 ||
    statusCode === 403 ||
    statusCode === 404 ||
    statusCode === 409 ||
    statusCode === 422 ||
    statusCode === 429 ||
    statusCode === 500
  ) {
    store.dispatch(showToast({msg: showErrorMsg(data), isError: true}));
    return {
      ...data,
      success: false,
    };
  } else if (statusCode === 401) {
    store.dispatch(resetAuthSlice());
    clearLocalStorage();
    window.location.href = RoutesName.signIn;
    store.dispatch(showToast({msg: showErrorMsg(data), isError: true}));
    return {
      ...data,
      success: false,
    };
  } else {
    store.dispatch(showToast({msg: showErrorMsg(data), isError: true}));
    return {
      data: {
        status: 0,
        message: '',
        data: {},
        error: [],
        meta: {},
      },
      success: false,
    };
  }
};

// Axios request interceptor
api.interceptors.request.use(
  async request => {
    // adding accessToken on api headers
    const tekenInfo = await getLocalStorageItem(StorageKeys.userSessionInfo);
    const accessToken = tekenInfo?.accessToken;
    request.headers.Authorization = `Bearer ${accessToken}`;
    return request;
  },
  error => {
    return Promise.reject(error);
  },
);

// Axios response interceptor
api.interceptors.response.use(
  async response => {
    return handleApiResponse(response);
  },
  error => {
    return Promise.reject(handleApiResponse(error.response));
  },
);

export default api;
