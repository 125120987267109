const Styles = {
  searchContainer: {
    mt: 3,
    mb: 3,
  },
  searchView: {
    display: 'flex',
    flexWrap: 'wrap',
    mt: 5,
    px: 6,
  },
  inputView: {
    width: 300,
  },
  searchBtnView: {
    display: 'flex',
    flexDirection: 'row',
    px: 5,
    mb: 3,
    mt: 0,
    justifyContent: 'right',
  },
  resetBtn: {
    mr: 2,
  },
  infoLabelView: {
    display: 'flex',
    pr: 2,
    mb: 1,
  },
  infoLeftView: {
    mr: 5,
  },
  modalStyle: {
    marginTop: 50,
  },
  modalBodyContainer: {
    display: 'flex',
    flexDirection: 'column',
    px: 4,
    pt: 3,
  },
  submitBtn: {
    mb: 4,
    mt: 1,
  },
  addInputView: {
    mb: 2,
  },
};

export default Styles;
