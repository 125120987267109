import React, {useState, useEffect} from 'react';
import {Routes, Route, useLocation} from 'react-router-dom';
import {ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import Sidenav from './views/components/Sidenav';
import ToastBox from './views/components/ToastBox';
import ProtectedRoute from './services/routes/ProtectedRoute';
import SignInPage from './views/pages/SignInPage';
import ForgotPasswordPage from './views/pages/ForgotPasswordPage';
import ResetPasswordPage from './views/pages/ResetPasswordPage';
import NotFoundPage from './views/pages/NotFoundPage';

import theme from './assets/theme';
import themeDark from './assets/theme-dark';
import routes from './services/routes';
import RoutesName from './services/routes/routesName';
import Strings from './constants/strings';
import StorageKeys from './services/storage/storageKeys';
import {useMaterialUIController, setMiniSidenav} from './context';
import {getLocalStorageItem} from './services/storage';

const App = () => {
  const [controller, dispatch] = useMaterialUIController();
  const {pathname} = useLocation();
  const {miniSidenav, direction, layout, sidenavColor, darkMode} = controller;
  const userSessionInfo = getLocalStorageItem(StorageKeys.userSessionInfo);

  const [onMouseEnter, setOnMouseEnter] = useState(false);

  useEffect(() => {
    document.body.setAttribute('dir', direction);
  }, [direction]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  const getRoutes = allRoutes =>
    allRoutes
      .filter(route => route?.roles?.includes(userSessionInfo?.data?.userRole))
      .map(route => {
        if (route.collapse) {
          return getRoutes(route.collapse);
        }
        if (route.route) {
          return (
            <Route
              exact
              path={route.route}
              element={route.component}
              key={route.key}
            />
          );
        }
        return null;
      });

  const getSideNavRoutes = allRoutes =>
    allRoutes.filter(route =>
      route?.roles?.includes(userSessionInfo?.data?.userRole),
    );

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      <ToastBox />
      {layout === 'dashboard' && (
        <>
          <Sidenav
            color={sidenavColor}
            brandName={Strings.disputeAlert}
            routes={getSideNavRoutes(routes)}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
        </>
      )}
      <Routes>
        <Route exact element={<SignInPage />} path={RoutesName.root} />
        <Route exact element={<SignInPage />} path={RoutesName.signIn} />
        <Route
          exact
          element={<ForgotPasswordPage />}
          path={RoutesName.forgotPassword}
        />
        <Route
          element={<ResetPasswordPage />}
          path={RoutesName.resetPassword}
        />
        <Route element={<ProtectedRoute />}>{getRoutes(routes)}</Route>
        <Route element={<NotFoundPage />} path="*" />
      </Routes>
    </ThemeProvider>
  );
};

export default App;
