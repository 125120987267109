import {createSlice} from '@reduxjs/toolkit';

const commonSlice = createSlice({
  name: 'commonSlice',
  initialState: {
    isToastVisible: false,
    isErrorToast: false,
    toastMessage: '',
  },
  reducers: {
    showToast: (state, {payload}) => {
      const {msg = '', isError = false} = payload;
      state.isToastVisible = !state.isToastVisible;
      state.isErrorToast = isError;
      state.toastMessage = msg;
    },
    resetCommonSlice: state => {
      state.isToastVisible = false;
      state.isErrorToast = false;
      state.toastMessage = '';
    },
  },
});

export default commonSlice.reducer;

export const {showToast, resetCommonSlice} = commonSlice.actions;
