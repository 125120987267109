import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

import MDBox from '../MDBox';
import MDTypography from '../MDTypography';

import Styles from './styles';

const LabelFilterView = props => {
  const {label, children} = props;

  return (
    <Box sx={Styles.container}>
      <MDTypography variant="body2" color="text" fontWeight="medium">
        {label}:
      </MDTypography>
      <MDBox sx={Styles.bodyView}>{children}</MDBox>
    </Box>
  );
};

// Setting default values for the props of MDBox
LabelFilterView.defaultProps = {
  label: '',
};

// Typechecking props for the MDBox
LabelFilterView.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node,
};

export default LabelFilterView;
