/* eslint-disable react/display-name */
import React, {forwardRef} from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';

import MDButtonRoot from './MDButtonRoot';
import {useMaterialUIController} from '../../../context';

const MDButton = forwardRef(
  (
    {
      loading,
      disabled,
      color,
      variant,
      size,
      circular,
      iconOnly,
      children,
      ...rest
    },
    ref,
  ) => {
    const [controller] = useMaterialUIController();
    const {darkMode} = controller;

    return (
      <MDButtonRoot
        disabled={loading || disabled}
        {...rest}
        ref={ref}
        color="primary"
        variant={variant === 'gradient' ? 'contained' : variant}
        size={size}
        ownerState={{color, variant, size, circular, iconOnly, darkMode}}>
        {loading ? <CircularProgress color="white" size={20} /> : children}
      </MDButtonRoot>
    );
  },
);

// Setting default values for the props of MDButton
MDButton.defaultProps = {
  loading: false,
  disabled: false,
  size: 'medium',
  variant: 'gradient',
  color: 'dark',
  circular: false,
  iconOnly: false,
};

// Typechecking props for the MDButton
MDButton.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  variant: PropTypes.oneOf(['text', 'contained', 'outlined', 'gradient']),
  color: PropTypes.oneOf([
    'white',
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'light',
    'dark',
  ]),
  circular: PropTypes.bool,
  iconOnly: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default MDButton;
