const Styles = {
  container: {
    paddingBottom: 2,
  },
  labelTxt: {
    mb: 1,
  },
  errorTxt: {
    mt: 0.5,
  },
};

export default Styles;
