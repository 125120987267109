import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

import MDBox from '../../components/MDBox';
import MDTypography from '../../components/MDTypography';
import TextInput from '../../components/TextInput';
import MDButton from '../../components/MDButton';
import PageLayout from '../../layouts/PageLayout';

import Strings from '../../../constants/strings';
import RoutesName from '../../../services/routes/routesName';
import Regex from '../../../constants/regex';
import Styles from './styles';
import {authBackgroundImg} from '../../../constants/images';
import {forgotPasswordApiCall} from '../../../services/api/apiCalls';
import {showToast} from '../../../redux/slices/commonSlice';

const ForgotPasswordPage = () => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .required(Strings.validationMsg.enterValidEmail)
        .matches(Regex.emailValidation, Strings.validationMsg.enterValidEmail),
    }),
    onSubmit: values => handleForgotPassClicked(values),
  });

  const handleForgotPassClicked = async values => {
    setIsLoading(true);
    const responseData = await forgotPasswordApiCall(values);

    if (responseData?.success) {
      dispatch(showToast({msg: responseData?.message}));
    }
    setIsLoading(false);
  };

  const renderCardHeader = () => {
    return (
      <MDBox
        variant="gradient"
        bgColor="dark"
        borderRadius="lg"
        coloredShadow="dark"
        sx={Styles.cardHeaderContainer}>
        <MDTypography
          variant="h3"
          fontWeight="medium"
          color="white"
          sx={Styles.forgotPassTxt}>
          {Strings.forgotPassword}
        </MDTypography>
        <MDTypography
          display="block"
          variant="body2"
          color="white"
          sx={Styles.youWillReceiveEmailTxt}>
          {Strings.youWillReceiveEmail}
        </MDTypography>
      </MDBox>
    );
  };

  const renderCardBody = () => {
    return (
      <MDBox sx={Styles.cardBodyContainer}>
        {/* email input */}
        <TextInput
          name={'email'}
          label={Strings.email}
          placeholder={Strings.enterEmail}
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          errors={formik.errors.email}
          touched={formik.touched.email}
          style={Styles.inputView}
        />
        {/* reset btn */}
        <MDBox sx={Styles.forgotPassBtnView}>
          <MDButton loading={isLoading} fullWidth onClick={formik.handleSubmit}>
            {Strings.requestResetPassword}
          </MDButton>
        </MDBox>
        {/* back to sign-in */}
        <MDBox sx={Styles.backBtnView}>
          <MDTypography
            component={Link}
            to={RoutesName.signIn}
            variant="body2"
            fontWeight="regular"
            color="dark">
            {Strings.backToSignIn}
          </MDTypography>
        </MDBox>
      </MDBox>
    );
  };

  return (
    <PageLayout>
      <MDBox
        sx={{
          backgroundImage: () => `url(${authBackgroundImg})`,
          ...Styles.container,
        }}
      />
      <MDBox sx={Styles.cardContainer}>
        <Grid
          container
          spacing={1}
          justifyContent="center"
          alignItems="center"
          height="100%">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            <Card>
              {renderCardHeader()}
              {renderCardBody()}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </PageLayout>
  );
};

export default ForgotPasswordPage;
