import React, {useState, useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import Menu from '@mui/material/Menu';

import MDBox from '../MDBox';
import MDButton from '../MDButton';
import MDTypography from '../MDTypography';
import Breadcrumbs from '../Breadcrumbs';

import Strings from '../../../constants/strings';
import RoutesName from '../../../services/routes/routesName';
import {navbar, navbarContainer, navbarRow, navbarIconButton} from './styles';
import {useMaterialUIController} from '../../../context';
import {resetAuthSlice} from '../../../redux/slices/authSlice';
import {clearLocalStorage} from '../../../services/storage';

const DashboardNavbar = props => {
  const {absolute, light, isMini} = props;
  const reduxDispatch = useDispatch();
  const navigate = useNavigate();

  const userSessionInfo = useSelector(
    state => state?.authSlice?.userSessionInfo?.data,
  );

  const [controller, dispatch] = useMaterialUIController();
  const {transparentNavbar, fixedNavbar, darkMode} = controller;
  const route = useLocation().pathname.split('/').slice(1);

  const [navbarType, setNavbarType] = useState();
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType('sticky');
    } else {
      setNavbarType('static');
    }
  }, [dispatch, fixedNavbar]);

  const onMenuClicked = event => {
    setIsMenuVisible(event.currentTarget);
  };

  const onMenuClose = () => {
    setIsMenuVisible(false);
  };

  const onLogoutClicked = () => {
    reduxDispatch(resetAuthSlice());
    clearLocalStorage();
    navigate(RoutesName.signIn);
  };

  // Styles for the navbar icons
  const iconsStyle = ({palette: {dark, white, text}, functions: {rgba}}) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  return (
    <AppBar
      position={absolute ? 'absolute' : navbarType}
      color="inherit"
      sx={theme =>
        navbar(theme, {transparentNavbar, absolute, light, darkMode})
      }>
      <Toolbar sx={theme => navbarContainer(theme)}>
        <MDBox
          color="inherit"
          mb={{xs: 1, md: 0}}
          sx={theme => navbarRow(theme, {isMini})}>
          <Breadcrumbs
            icon="home"
            title={route[route.length - 1]}
            route={route}
            light={light}
          />
        </MDBox>
        <IconButton sx={navbarIconButton} onClick={onMenuClicked}>
          <MDTypography variant="body2" fontWeight="medium" color="dark" mr={1}>
            {`${userSessionInfo?.firstName || '--'} ${userSessionInfo?.lastName || '--'}`}
          </MDTypography>
          <Icon sx={iconsStyle} fontSize={'inherit'}>
            account_circle
          </Icon>
        </IconButton>
        <Menu
          anchorEl={isMenuVisible}
          open={Boolean(isMenuVisible)}
          onClose={onMenuClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}>
          <MDButton variant="text" onClick={onLogoutClicked}>
            <Icon sx={{mr: 1}}>logout</Icon>
            {Strings.logout}
          </MDButton>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
