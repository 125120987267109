const Styles = {
  inputView: {},
  labelTxt: {
    mb: 1,
  },
  errorTxt: {
    mt: 0.5,
  },
};

export default Styles;
