import React, {useMemo, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useTable, usePagination, useGlobalFilter, useSortBy} from 'react-table';
import {Pagination, Empty} from 'antd';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

import MDBox from '../MDBox';
import DataTableHeadCell from './DataTableHeadCell';
import DataTableBodyCell from './DataTableBodyCell';

import Strings from '../../../constants/strings';

const DataTable = props => {
  const {
    table,
    isSorted,
    noEndBorder,
    currentPage,
    totalPage,
    pageSize,
    paginationOnChange,
    renderHeader,
  } = props;
  const columns = useMemo(() => table.columns, [table]);
  const data = useMemo(() => table.rows, [table]);

  const tableInstance = useTable(
    {columns, data, initialState: {pageIndex: 0}},
    useGlobalFilter,
    useSortBy,
    usePagination,
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    setPageSize,
  } = tableInstance;

  useEffect(() => setPageSize(10), []);

  // A function that sets the sorted value for the table
  const setSortedValue = column => {
    let sortedValue;

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? 'desc' : 'asce';
    } else if (isSorted) {
      sortedValue = 'none';
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

  return (
    <TableContainer>
      {/* table header */}
      <MDBox
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'right',
          px: 5,
          mb: 2,
          mt: 3,
        }}>
        {renderHeader}
      </MDBox>
      {/* table body */}
      <Table {...getTableProps()}>
        <MDBox component="thead">
          {headerGroups.map((headerGroup, key) => (
            <TableRow key={key} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, idx) => (
                <DataTableHeadCell
                  key={idx}
                  {...column.getHeaderProps(
                    isSorted && column.getSortByToggleProps(),
                  )}
                  width={column.width ? column.width : 'auto'}
                  align={column.align ? column.align : 'left'}
                  sorted={setSortedValue(column)}>
                  {column.render('Header')}
                </DataTableHeadCell>
              ))}
            </TableRow>
          ))}
        </MDBox>
        <TableBody {...getTableBodyProps()}>
          {page.map((row, key) => {
            prepareRow(row);
            return (
              <TableRow key={key} {...row.getRowProps()}>
                {row.cells.map((cell, idx) => (
                  <DataTableBodyCell
                    key={idx}
                    noBorder={noEndBorder && rows.length - 1 === key}
                    align={cell.column.align ? cell.column.align : 'left'}
                    {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </DataTableBodyCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {rows.length === 0 && (
        <Empty
          description={Strings.noDataFound}
          imageStyle={{height: 200}}
          style={{
            margin: 50,
          }}
        />
      )}
      {/* table footer */}
      <MDBox
        display="flex"
        flexDirection={{xs: 'column', sm: 'row'}}
        justifyContent="space-between"
        alignItems={{xs: 'flex-start', sm: 'center'}}
        p={totalPage === 1 ? 1 : 3}>
        <Pagination
          hideOnSinglePage
          current={currentPage}
          total={totalPage}
          pageSize={pageSize}
          showSizeChanger={false}
          showTotal={total => `Total ${total} items`}
          onChange={paginationOnChange}
        />
      </MDBox>
    </TableContainer>
  );
};

// Setting default values for the props of DataTable
DataTable.defaultProps = {
  isSorted: true,
  noEndBorder: false,
  currentPage: 1,
  totalPage: 0,
  pageSize: 10,
};

// Typechecking props for the DataTable
DataTable.propTypes = {
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
  currentPage: PropTypes.number,
  totalPage: PropTypes.number,
  pageSize: PropTypes.number,
  paginationOnChange: PropTypes.func,
  renderHeader: PropTypes.node,
};

export default DataTable;
