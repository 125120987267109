const Styles = {
  inputView: {
    width: '100%',
    mb: 2,
  },
  modalStyle: {
    marginTop: 50,
  },
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
    px: 4,
    pt: 3,
  },
  submitBtn: {
    mb: 4,
    mt: 1,
  },
  testConnection: {
    mb: 2,
    mt: 1,
  },
};

export default Styles;
