import React from 'react';
import PropTypes from 'prop-types';
import {Doughnut} from 'react-chartjs-2';
import {Empty} from 'antd';
import {Chart as ChartJS, ArcElement, Tooltip, Legend} from 'chart.js';
import Card from '@mui/material/Card';

import MDBox from '../MDBox';
import MDTypography from '../MDTypography';

import configs from './configs';
import Styles from './styles';

ChartJS.register(ArcElement, Tooltip, Legend);

const DefaultDoughnutChart = props => {
  const {title, height, chart} = props;
  const {data, options} = configs(
    chart.labels || [],
    chart.datasets || {},
    chart.cutout,
  );

  return (
    <Card style={Styles.container}>
      <MDBox sx={Styles.headerContainer}>
        <MDTypography variant="h6">{title}</MDTypography>
      </MDBox>
      {chart?.fulldata.length != 0 ? (
        <MDBox sx={Styles.bodyContainer}>
          <MDBox height={height}>
            <Doughnut data={data} options={options} />
          </MDBox>
          <MDBox sx={Styles.infoContainer}>
            {chart?.fulldata?.map((item, index) => {
              return (
                <MDBox key={index} sx={Styles.chartInfiBox}>
                  <MDBox
                    sx={{
                      ...Styles.colorBox,
                      backgroundColor: item?.color,
                    }}></MDBox>
                  <MDTypography variant="h6" mr={1}>
                    {`${item?.label} - ${item?.count}`}
                  </MDTypography>
                </MDBox>
              );
            })}
          </MDBox>
        </MDBox>
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </Card>
  );
};

// Setting default values for the props of DefaultDoughnutChart
DefaultDoughnutChart.defaultProps = {
  title: '',
  height: '19.125rem',
};

// Typechecking props for the DefaultDoughnutChart
DefaultDoughnutChart.propTypes = {
  title: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  chart: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  ).isRequired,
};

export default DefaultDoughnutChart;
