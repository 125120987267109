import {checkNullOrEmptyValue} from '../../../utility/validations';

export const userModel = data => {
  const newData = data?.data;
  const userData = data?.data?.data;
  const payload = {};

  payload.data = {
    id: checkNullOrEmptyValue(userData?.id),
    userRole: checkNullOrEmptyValue(userData?.userRole),
    email: checkNullOrEmptyValue(userData?.email),
    firstName: checkNullOrEmptyValue(userData?.firstName),
    lastName: checkNullOrEmptyValue(userData?.lastName),
    createdAt: checkNullOrEmptyValue(userData?.createdAt),
    updatedAt: checkNullOrEmptyValue(userData?.updatedAt),
    deletedAt: checkNullOrEmptyValue(userData?.deletedAt),
  };
  payload.error = checkNullOrEmptyValue(newData?.error);
  payload.accessToken = checkNullOrEmptyValue(newData?.meta?.accessToken);
  payload.message = checkNullOrEmptyValue(newData?.message);
  payload.success = data.success;
  payload.status = data.status;

  return payload;
};

export const usersModel = data => {
  const newData = data?.data;
  const usersData = data?.data?.data;
  const payload = {};

  payload.data = parseUsersItem(usersData);
  payload.error = checkNullOrEmptyValue(newData?.error);
  payload.meta = checkNullOrEmptyValue(newData?.meta);
  payload.message = checkNullOrEmptyValue(newData?.message);
  payload.success = data.success;
  payload.status = data.status;

  return payload;
};

// users []
const parseUsersItem = (resultsData = []) => {
  const payload = [];

  resultsData.forEach(item => {
    const resultItem = {};

    resultItem.id = checkNullOrEmptyValue(item?.id);
    resultItem.userRole = checkNullOrEmptyValue(item?.userRole);
    resultItem.email = checkNullOrEmptyValue(item?.email);
    resultItem.firstName = checkNullOrEmptyValue(item?.firstName);
    resultItem.lastName = checkNullOrEmptyValue(item?.lastName);

    payload.push(resultItem);
  });

  return payload;
};
